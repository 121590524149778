import React, { useState, useEffect, useMemo, useContext } from 'react'
import { useForm } from "react-hook-form"
import CopyInput from '../../components/copyInput'
import Button from '../../components/button'
import { useUser } from '../../hooks/useUser'
import { useApi } from '../../hooks/useApi'
import { navigate } from "gatsby"
import UiStoreContext from '../../contexts/uiStoreContext'
import tw from 'twin.macro'
import AccountLayout from './components/accountSettingsLayout'
import emailValidator from 'email-validator'
import {
	faSave,
	faTimes,
} from '@fortawesome/free-solid-svg-icons'

const Profile = ({...props}) => {

	const { callApi } = useApi()
	const { user, login, refreshAuthenticatedUser } = useUser()
	const { register, errors, reset, formState: {dirtyFields}, handleSubmit } = useForm({
		defaultValues: {
		 	email: user && user.email
		}
	});
	const [changeEmailError, setChangeEmailError] = useState();
	const uiStore = useContext(UiStoreContext);
	const [spin, setSpin] = useState(false)

	useEffect(() => {
		refreshAuthenticatedUser()
	}, [])

	useEffect(() => {
		if (user) {
			reset({
				email: user.email
			})
		}
	}, [user])

	const onSubmit = async (data) => {
		try {
			setSpin(true)
			await login(user.email, data.password)
			await callApi({
				method: 'patch',
				namespace: `me`,
				payload: {
					email: data.email
				} 
			})
			navigate("/verify", {
				state: {
					email: data.email
				}
			});
		}
		catch (err) {
			setChangeEmailError(err.message);
			// TODO: handle
			console.log(err);
		}
		finally {
			setSpin(false)
		}
	};

	const handleCancelUpdateEmail = (e) => {
		reset({
			email: user.email,
			password: ''
		})
	}

	if (!user) {
		return null;
	}

	return <AccountLayout tab="profile" {...props}>
		<div tw="">
			<h2>Your file.io user id</h2>
			<form tw="mb-8">
				<CopyInput readOnly tw="w-full" id="email" value={user ? user.username : ''}/>
			</form>
			<h2>Update your email address</h2>
			<form onSubmit={handleSubmit(onSubmit)}>
				<label htmlFor="email">Email address</label>
				<input
					name="email"
					type="text"
					placeholder="New Email Address"
					ref={register({
						required: true,
						validate: value => {
							return emailValidator.validate(value);
						}
					})}
				></input>
				{errors.email && <div className="error">Please enter a valid email address</div>}
				<label htmlFor="password">Password</label>
				<input
					name="password"
					type="password"
					placeholder="Password"
					disabled={!dirtyFields['email']}
					ref={register({
						required: true
					})}
				></input>
				{errors.password && <div className="error">Password is required</div>}
				{changeEmailError && <div className="error">{changeEmailError}</div>}
				<div tw="flex flex-col md:flex-row-reverse mt-4">
					<Button icon={faSave} type="submit" special disabled={!dirtyFields['email']} spin={spin}>Save New Email Address</Button>
					<Button icon={faTimes} tw="mt-2 md:mt-0 md:mr-2" onClick={handleCancelUpdateEmail} disabled={!dirtyFields['email']}>Cancel</Button>
				</div>
			</form>
		</div>
	</AccountLayout>
};

export default Profile
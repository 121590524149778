import react, {useState, useEffect, useRef} from "react"
import Clipboard from 'react-clipboard.js';
import Button from './button'
import Icon from './icon'
import {
	faCopy,
	faThumbsUp,
} from '@fortawesome/free-solid-svg-icons'
import useTimeout from '../hooks/useTimeout';
import "twin.macro"

const CopyInput = (props) => {
	const [showThumb, setShowThumb] = useState(false)
	const timerRef = useRef()

	useEffect(() => {
		return () => clearTimeout(timerRef.current)
	}, [])

	const handleCopySuccess = (e) => {
		clearTimeout(timerRef.current)
		setShowThumb(true)
		timerRef.current = setTimeout(() => setShowThumb(false), 2000)
	}

	const handleClickInput = (e) => {
		e.target.select()
	}

	return  <div tw="w-full flex items-start">
		<input {...props} readOnly {...props} onClick={handleClickInput}/>
		<Clipboard data-clipboard-text={props.value} onSuccess={handleCopySuccess} component="div">
			<Button tw="ml-2" type="button" icon={showThumb ? faThumbsUp : faCopy}>{showThumb ? 'Copied!' : 'Copy'}</Button>
		</Clipboard>
	</div>
	
}

export default CopyInput